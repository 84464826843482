$desktop-min-width: 992px;
$tablet-min-width: 1300px;

$primary-blue: #537c89;
$secondary-blue: #edf1f4;
$primary-green: #74be8b;
$primary-red: #ef776f;
$primary-grey: #414141;
$secondary-grey: #838282;
$primary-black: #212121;
$secondary-black: #2b2b2b;

$page-margin-left: 1.5rem;
$desktop-page-margin-left: 20vw;
$desktop-secondary-page-margin: 15vw;

$desktop-min-width: 992px;
