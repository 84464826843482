@import './vars.sass';

::-webkit-progress-value, ::-moz-progress-bar {
  background: #0dc5b9; }

.help-block {
  font-size: 0.9em; }

#id_caisse {
  margin-bottom: 8px; }

#id_distancevp_container .help-block, #id_region_container .help-block, #id_caisse_container .help-block {
  background-color: #005DA8;
  padding: 6px;
  font-weight: bold;
  color: #ffffff; }

#id_trip_kind_container .radio,
#id_pmt_pel_container .radio {
  display: inline-block; }

#id_pmt_pel {
  margin-right: 20px; }
#id_pmt_pel > label > .item-label {
  padding-left: 25px; }

.select-dropdown li {
  list-style-type: none !important; }

.mrs-std-page--wrapper {
  padding: 0;
  color: black;

  @media (min-width: $desktop-min-width) {
    padding: 0 5vw;
    margin-top: 3rem /* thin header height */; }

  @media (min-width: 1300px) {
    padding: 0 $desktop-secondary-page-margin; }

  .mrs-std-page {
    background-color: white;
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 0;
    @media (min-width: 401px) {
      padding: 7%; }
    min-height: calc(100vh - 11rem);

    @media (min-width: $desktop-min-width) {
      min-height: calc(100vh - 7rem); }

    &.no-padding {
      padding: 0; }

    &.small-padding {
      padding: 0 2rem; }


    h2 {
      font-size: 2rem;
      line-height: 3rem;
      text-align: center; }

    p {
      text-align: center; } }

  .contact-wrapper {
    padding: 7%;
    margin-bottom: 3rem;
    a {
      color: #039be5; }

    li {
      list-style-type: disc;
      text-align: left;
      list-style-position: inside; } }

  .with-ul {
    li {
      list-style-type: disc;
      list-style-position: inside; } } }

form {
  .error {
    color: #F44336;
    display: block;
    cursor: default !important; }

  small.error {
    /* cancel out small */
    font-size: 100% !important; } }

#mrsrequest-form {
  .warning {
    color: DarkOrange;
    display: block; } }

#caisse-selector {
  position: relative;
  .help-button {
    border-radius: 50%;
    position: absolute;
    top: 2.5em;
    right: 0;
    padding: .10em 0.6em;

    background-color: #005DA8;
    color: #fff;
    font-weight: bold;

    @media (min-width: 576px) {
      top: 1.5em;
      right: 1em;
      padding: .5em 1em; } } }

#pmt-form {
  position: relative;
  .help-button {
    border-radius: 50%;
    position: absolute;
    top: 5em;
    right: 0;
    padding: .10em 0.6em;

    background-color: #005DA8;
    color: #fff;
    font-weight: bold;

    @media (min-width: 500px) {
      top: 4em; }

    @media (min-width: 715px) {
      padding: .5em 1em; }

    @media (min-width: 890px) {
      top: 0;
      right: 0;
      padding: .5em 1em; } } }

select {
  opacity: 1 !important;
  display: block !important; }

.caret {
  display: none !important; }

.select-wrapper input.select-dropdown {
  display: none !important; }

input:not([type]), input[type=text]:not(.browser-default), input[type=password]:not(.browser-default), input[type=email]:not(.browser-default), input[type=url]:not(.browser-default), input[type=time]:not(.browser-default), input[type=date]:not(.browser-default), input[type=datetime]:not(.browser-default), input[type=datetime-local]:not(.browser-default), input[type=tel]:not(.browser-default), input[type=number]:not(.browser-default), input[type=search]:not(.browser-default), textarea.materialize-textarea {
  background-color: #ffffff !important; }

.input-field > label:not(.label-icon).active {
    transform: translateY(-20px) scale(0.8) !important; }

input::placeholder,
.input-field > label {
  color: #6d6d6d;
  padding-left: 8px; }


.demande-section-header {
  text-transform: uppercase;
  font-size: 24px;
  line-height: 28px;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;

  @media (max-width: 576px) {
    margin: 0 1rem 1rem;
    .numero {
      margin-right: 10px; } }

  .numero {
    border: 2px solid;
    border-radius: 26px;
    width: 45px;
    height: 45px;
    display: flex;
    flex-shrink: 0;
    justify-content: center;
    align-items: center;
    margin-right: 20px;

    @media (min-width: 992px) {
      margin-left: -70px; } } }


.demande-section {
  background-color: #e7f5f4;
  padding: 20px 20px 0 20px;
  margin-bottom: 4rem; }

[type="radio"]:checked + span:after, [type="radio"].with-gap:checked + span:before, [type="radio"].with-gap:checked + span:after {
  border-color: #005DA8 !important; }

[type="radio"]:checked + span:after, [type="radio"].with-gap:checked + span:after {
  background-color: #005DA8 !important;
  border-color: #005DA8 !important; }

input:not([type]):focus:not([readonly]) + label, input[type=text]:not(.browser-default):focus:not([readonly]) + label, input[type=password]:not(.browser-default):focus:not([readonly]) + label, input[type=email]:not(.browser-default):focus:not([readonly]) + label, input[type=url]:not(.browser-default):focus:not([readonly]) + label, input[type=time]:not(.browser-default):focus:not([readonly]) + label, input[type=date]:not(.browser-default):focus:not([readonly]) + label, input[type=datetime]:not(.browser-default):focus:not([readonly]) + label, input[type=datetime-local]:not(.browser-default):focus:not([readonly]) + label, input[type=tel]:not(.browser-default):focus:not([readonly]) + label, input[type=number]:not(.browser-default):focus:not([readonly]) + label, input[type=search]:not(.browser-default):focus:not([readonly]) + label, textarea.materialize-textarea:focus:not([readonly]) + label {
  color: #005DA8 !important; }

input:not([type]):focus:not([readonly]), input[type=text]:not(.browser-default):focus:not([readonly]), input[type=password]:not(.browser-default):focus:not([readonly]), input[type=email]:not(.browser-default):focus:not([readonly]), input[type=url]:not(.browser-default):focus:not([readonly]), input[type=time]:not(.browser-default):focus:not([readonly]), input[type=date]:not(.browser-default):focus:not([readonly]), input[type=datetime]:not(.browser-default):focus:not([readonly]), input[type=datetime-local]:not(.browser-default):focus:not([readonly]), input[type=tel]:not(.browser-default):focus:not([readonly]), input[type=number]:not(.browser-default):focus:not([readonly]), input[type=search]:not(.browser-default):focus:not([readonly]), textarea.materialize-textarea:focus:not([readonly]) {
  border-bottom: 1px solid #005DA8 !important;
  box-shadow: 0 1px 0 0 #005DA8 !important; }

label {
  color: #6d6d6d !important; }

.item-label {
  color: initial; }

#id_pmt_container .btn,
#id_billatps_container .btn,
#mrsrequest-wizard .btn:not([data-btn-exclude="1"]),
#contact .btn {
  background-color: #005DA8 !important;
  color: #ffffff !important;
  display: flex;
  flex-direction: column;
  justify-content: center;

  &:hover {
    background-color: #003763; } }

.file-path-wrapper {
  display: none;

  @media (min-width: 576px) {
    display: block;
    visibility: hidden; } }

#id_pmt_container .btn,
#id_billvps_container .btn,
#id_billatps_container .btn {
  width: 100%;
  margin-bottom: 8px;

  @media (min-width: 576px) {
    width: 20em; } }

#mrsrequest-wizard {
  margin-bottom: 3rem; }

[type="checkbox"].filled-in:checked + span:not(.lever):after,
[type="checkbox"].filled-in.tabbed:checked:focus + span:not(.lever):after {
  border: 2px solid #005DA8 !important;
  background-color: #005DA8 !important; }

.checkbox-field {
  label {
    color: #000000 !important; }

  .help-block {
    color: #6d6d6d !important; } }

#id_iterative_number_container,
#id_distancevp_container,
#id_expenseatp_container {
  margin-top: 2rem; }

input[data-form-control="date"],
#id_first_name_container input,
#id_last_name_container input,
#id_nir_container input,
#id_email_container input,
#id_iterative_number_container input,
#id_distancevp_container input,
#id_expensevp_toll_container input,
#id_expensevp_parking_container input,
#id_expenseatp_container input {
  padding-left: 8px !important; }

.input-field > input[type]:-webkit-autofill:not(.browser-default):not([type="search"]) + label, .input-field > input[type=date]:not(.browser-default) + label, .input-field > input[type=time]:not(.browser-default) + label {
  transform: translateY(-20px) scale(0.8) !important; }

.file-field {
  .files {
    margin-top: 10px;

    li {
      margin-top: 0.5rem;

      .file-name {
        font-weight: bold; }

      .delete-file {
        font-size: 0.8rem;
        padding: 0.6rem 0.3rem;
        height: 1.5rem;
        margin-right: .8rem; }

      .error {
        font-weight: bold;

        &:last-child {
          display: none; } } } } }

input[name="pmt"],
input[name="billvps"],
input[name="billatps"] {
  height: 3rem; }

#caisse-form {
  label {
    color: #000000;
    font-size: 1rem; } }

#submitui-message {
  text-align: center;
  background-color: #e7f5f4;
  padding: 50px;
  margin-bottom: 3rem; }

.btn[type="submit"] {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto; }

label[for="id_distancevp"] {
  white-space: nowrap; }

#validation-detail-h,
#rejet-potentiel-h {
  padding: 0 7%;
  margin-bottom: 1rem; }

#validation-detail {
  padding: 0 7%;
  font-size: 1.2rem;
  margin-bottom: 2rem;

  p {
    text-align: left !important; } }

#validation-detail-check {
  margin-bottom: 1rem;
  width: 7rem;

  @media (min-width: 576px) {
    width: 10rem; } }

#validation-actions {
  margin-bottom: 3rem;
  display: flex;
  flex-direction: column;

  @media (min-width: 576px) {
    flex-direction: row;
    justify-content: space-evenly; }

  > a {
    margin: 0 auto 2rem;

    //@media (min-width: 576px)
 } }    //  width: 100%

#rejet-potentiel {
  padding: 7%; }

#rejet-potentiel-btns {
  padding: 7%; }

#id_modeatp_container {
  margin-bottom: 20px; }

#id_convocation {
  margin-top: 6em;

  @media (min-width: 310px) {
    margin-top: 4em; }

  @media (min-width: 540px) {
    margin-top: 2em; }

  @media (min-width: 940px) {
    margin-top: 0; }

  @media (min-width: 991px) {
    margin-top: 2em; }

  @media (min-width: 1045px) {
    margin-top: 0; }

  @media (min-width: 1300px) {
    margin-top: 2em; }

  @media (min-width: 1339px) {
    margin-top: 0; } }
