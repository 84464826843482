.ui {
  &.flex {
    &.column {
      display: flex;
      flex-direction: column; }

    &.flex {
      &.row {
        display: flex;
        flex-direction: row; } } } }

#stats-table {
  margin: 3rem 0; }
