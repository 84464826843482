@import './vars.sass';


//
// FONTS
//
@mixin barlow-condensed-bold() {
  font-family: 'Barlow Condensed', serif;
  font-weight: 700; }

@mixin barlow-bold() {
  font-family: 'Barlow', serif;
  font-weight: 700; }

@mixin barlow-extra-bold() {
  font-family: 'Barlow', serif;
  font-weight: 800; }

@mixin faustina-bold-italic() {
  font-family: 'Faustina', serif;
  font-weight: 700;
  font-style: italic; }

@mixin barlow-regular() {
  font-family: 'Barlow', serif;
  font-weight: 400; }


//
// LAYOUT
//
@mixin flex-column() {
  display: flex;
  flex-direction: column; }

@mixin flex-center() {
  display: flex;
  justify-content: center;
  align-items: center; }

@mixin wrapper() {
  padding-left: $page-margin-left;

  @media (min-width: $desktop-min-width) {
    padding-left: $desktop-page-margin-left; } }


//
// UI
//
@mixin button($bg-color) {
  @include barlow-condensed-bold();
  $height: 2.5rem;
  border-radius: $height;
  background-color: $bg-color;
  padding: 0.5rem 1rem;
  height: $height;
  cursor: pointer;

  @media (min-width: $desktop-min-width) {
    $height: 2rem;
    border-radius: $height;
    height: $height;
    padding-top: 0;
    padding-bottom: 0;
    display: flex;
    align-items: center; } }
