html {
  font-size: 16px;

  #background-img--wrapper {
    height: 35rem; } }

section h1 {
  font-size: 2.2rem; }

.process--wrapper {
  flex-direction: column;

  > img {
    margin-right: 0;
    margin-bottom: 2rem; } }

#s1 {
  min-height: 30rem;

  .center--wrapper {
    margin: 1rem 2vh; }

  .section-content--wrapper {
    min-height: 400px; } }

#s5 .section-content--wrapper {
  padding: 0; }
