@import './mixins';
@import './UI';
@import './rating';

$desktop-min-width: 992px;

html {
  max-width: 100vw;
  font-size: 20px; }

body {
  @include flex-center();
  flex: 1;
  flex-direction: column;
  color: white;

  div.captcha {
    div.image {
      text-align: center; }

    @media (min-width: 500px) {
      display: flex;
      align-items: end; } }

  .pointer {
    cursor: pointer; }

  p {
    width: 100%; }

  .btn[type="submit"] {
    @include flex-center();
    margin: auto; } }
