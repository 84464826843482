

#background-img--wrapper {
  height: 100vh;
  position: absolute;
  top: 0;
  background-size: cover;
  background-position-x: 50%;
  background-repeat: no-repeat;
  z-index: -1;
  left: 0;
  right: 0; }



//
//  UI elements
//
.section-arrow--wrapper {
  display: flex;
  width: 100%;

  .arrow-left, .arrow-right {
    height: 0rem;
    border-bottom: 1.3rem solid #0dc5b9;
    width: 50%; }

  .arrow-left {
    border-right: 1.3rem solid transparent; }

  .arrow-right {
    border-left: 1.3rem solid transparent; } }

//
//Material UI overrides
//
form .section.row input[type="file"] {
  overflow: hidden; }

//
//  Inline tag styles
//
strong {
  font-weight: bold; }

//
// Inline class styles
//
.button {
  padding: 1rem 2rem;
  border-radius: 0.4rem;
  border: 2px solid white;
  cursor: pointer;
  transition: background-color 0.1s linear;

  &:hover {
    background-color: rgba(255, 255, 255, 0.1); } }

//
//  Landing page styles
//
#app--wrapper {
  width: 100%; }

section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem 7rem;
  padding: 1rem 10vw;

  .section-content--wrapper {
    width: 100%;
    max-width: 56rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; } }

.section-content--wrapper > {
  h1, h2, h3 {
    text-align: center; } }

section {
  h1 {
    font-size: 3rem;
    font-weight: bold;
    width: 100%; }

  h2 {
    font-size: 2rem;
    width: 100%;

    &.caps {
      font-size: 1.5rem; } }

  h3 {
    font-size: 1.25rem;
    width: 100%; } }

//
//  Section 1
//
#s1 {
  min-height: 70vh;
  position: relative;

  .center--wrapper {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 1rem 10vh; }

  .section-content--wrapper > p {
    text-align: center; } }

//
//  Section 2
//
#s2 {
  background-color: #0dc5b9;

  p {
    text-align: center; } }

#today-tomorrow--wrapper {
  align-self: flex-start; }

.bullet--wrapper {
  display: flex;
  flex-direction: column;

  h2 {
    font-size: 1.5rem; }

  ul {
    font-size: 1rem;
    list-style-type: disc;
    padding-left: 3rem;

    li {
      list-style-type: disc; } } }

//
//  Section 3
//
#s3 {
  background-color: #537c89; }

.processes--wrapper {
  width: 100%; }

.process--wrapper {
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 10rem;
  padding: 1rem;

  img {
    /* see process--wrapper ; height - 2 * padding = 8rem */
    height: 8rem;
    width: 8rem;
    margin-right: 2rem; }

  .description--wrapper {
    background-color: white;
    padding: 1rem;
    border-radius: 5px;
    flex: 1;

    h2 {
      color: #537c89;
      font-size: 1.2rem;
      font-weight: bold;
      margin: 1rem 0; }

    p {
      color: #999; } } }

//
//  Section 4
//
#s4 {
  background-color: white;
  color: black;

  .form--wrapper {
    width: 80%; } }

.disclaimer--wrapper {
  color: rgba(0, 0, 0, 0.6);
  font-style: italic;
  font-size: 0.7rem;
  width: 100%; }

#s4 p {
  text-align: center; }

//
//  Section 5
//
#s5 {
  background-color: #2f343b;

  .section-content--wrapper {
    padding: 0 4rem; } }

#youtube-video--wrapper-1 {
  width: 100%; }

#youtube-video--wrapper-2 {
  width: 100%;
  margin: 2rem 0;
  // https://css-tricks.com/NetMag/FluidWidthVideo/Article-FluidWidthVideo.php
  position: relative;
  padding-bottom: 56.25%;
  // 16:9
  padding-top: 25px;
  height: 0;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; } }


.plus-d-info {
  color: #0dc5b9; }

//
//  Section 6
//
#s6 {
  background-color: white;
  color: black;

  form {
    width: 80%;
    margin-bottom: 2rem; }

  .logo {
    height: 3.3rem;
    margin-bottom: 0.5rem; } }

//
//  Section 7
//
#s7 {
  background-color: #7ec194;

  h2 {
    text-align: center; }

  h3 {
    font-style: italic;
    text-align: center; }

  .temoignages--wrapper {
    margin: 2rem;
    width: 100%;

    .temoignage--wrapper {
      margin: 1rem;

      .author {
        font-size: 1.2rem;
        margin-bottom: 0.5rem;
        color: #547e89;
        font-weight: bold; }

      .citation {
        font-size: 0.8rem;
        font-style: italic; } } } }


//
// Media queries
//
@media (max-width: 600px) {
  @import './main-mobile'; }
