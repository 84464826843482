@import './mixins.sass';
@import './vars.sass';

#control--wrapper {
  border-left: 0;

  .thumb {
    background-color: rgba(0, 0, 0, 0);
    border: 1px solid $secondary-blue;
    border-left: 0;
    align-items: center;
    padding-right: 1rem;
    padding-left: 1rem;
    border-radius: 0;
    width: 100vw;
    height: auto;
    font-size: 0.8rem;
    padding: 1rem;


    @media screen and (min-width: 500px) {
      padding: 2rem; }

    @media screen and (min-width: 800px) {
      transform: translateY(-120%);
      $height: 5rem;
      height: $height;
      width: calc(#{$desktop-page-margin-left} + 50rem);
      max-width: 100rem;
      padding-left: $desktop-page-margin-left;
      width: 80vw;
      border-top-right-radius: $height;
      border-bottom-right-radius: $height;
      font-size: 0.7rem; }

    @media screen and (min-width: $tablet-min-width) {
      padding-left: $desktop-page-margin-left; }



    p {
      margin: 0; } } }

#control {
  transform: translateY(-100%); }

.thumb--wrapper {
  position: relative;
  height: 0;

  .thumb {
    $height: 7rem;
    position: absolute;
    height: $height;
    width: 20rem;
    border-top-right-radius: $height;
    border-bottom-right-radius: $height;
    background-color: red;
    display: flex;
    justify-content: center; } }

#index--wrapper {
  // for cropping bus and auto icons in 3 etapes when necessary on desktop
  overflow-x: hidden;

  .mrs-logo--wrapper {
    @include flex-column();
    align-items: center;
    background-color: white;

    @media (min-width: $desktop-min-width) {
      flex-direction: row; }

    .mrs-logo {
      width: 70vw;
      max-width: 15rem;
      margin: 5rem 0 2rem 0;

      @media (min-width: $desktop-min-width) {
        margin-left: $desktop-page-margin-left;
        margin-bottom: 3rem; } }

    #intro--wrapper {
      @include flex-center();
      $padding-left: $page-margin-left;

      flex-direction: column;
      width: 100%;
      background-color: $primary-blue;
      color: $secondary-blue;
      position: relative;
      height: 5rem;
      padding: 0 $padding-left;

      @media (max-width: $desktop-min-width) {
        text-align: center; }

      @media (min-width: $desktop-min-width) {
        background-color: rgba(0, 0, 0, 0);
        color: $primary-blue;
        transform: translateY(-0.5rem);
        max-width: 24rem;
        margin-top: 3rem; }

      p {
        margin: 0;
        font-size: 1.2rem;
        font-weight: bold;
        line-height: 1.3rem; }

      .start-button {
        @include button($primary-red);
        position: absolute;
        top: calc(100% + 1rem);
        color: white;

        @media (min-width: $desktop-min-width) {
          align-self: flex-start;
          transform: translateY(-20px)!important;
          position: absolute;
          display: flex;
          left: $padding-left; } } } }

  #intro-bg-img {
    background-size: cover;
    height: 60vw;
    max-height: 30rem; }

  #intro-descr--wrapper {
    .thumb {
      @include flex-column();
      @include wrapper();
      transform: translateY(-100%);
      background-color: $primary-green;
      color: white;
      padding-right: 3rem;
      width: 85vw;
      max-width: 50rem;

      @media screen and (min-width: $desktop-min-width) {
        width: calc(#{$desktop-page-margin-left} + 30rem);
        max-width: 100rem; }


      h3 {
        @include faustina-bold-italic();
        font-weight: bold;
        font-style: italic;
        margin: 0;
        margin-bottom: 0.5rem;
        font-size: 0.9rem;

        @media screen and (min-width: 415px) {
          font-size: 1.2rem; } }

      p {
        @include barlow-regular();
        line-height: 0.9rem;
        margin: 0;
        font-size: 0.7rem;

        @media screen and (min-width: 415px) {
          font-size: 0.9rem; } } } }

  #hier-ajd--wrapper {
    @include flex-column();
    @include wrapper();
    background-color: $secondary-blue;
    color: $primary-blue;
    padding: 0 !important;

    @media (min-width: $desktop-min-width) {
      justify-content: space-between; }

    a {
      color: $primary-blue;
      border: 0; }

    .start-button {
      @include button($primary-blue);
      height: 3rem;
      font-size: 1.3rem;
      color: white;
      align-self: center;
      margin: 1rem 0;

      @media (min-width: $desktop-min-width) {
        display: none; } }

    .wrapper2 {
      display: flex;
      @include flex-column();

      @media (min-width: $desktop-min-width) {
        flex-direction: row; } }

    .conclusion--wrapper {
      display: flex;
      margin: 1rem 0;

      .conclusion {
        @include barlow-regular();
        font-size: 1.2rem;
        padding-right: 1rem;
        padding-left: 0.5rem; }

      .arrow {
        font-size: 1.2rem; } }

    .hier--wrapper {
      @include flex-column();
      padding-left: 1rem;

      h3 {
        @include faustina-bold-italic();
        margin: 0;
        display: flex;
        height: 4rem;
        align-items: center; } }


    .hier--wrapper h3 span, .ajd--wrapper h3 span {
      @include button(white);
      font-size: 1.2rem;
      color: $primary-red;
      font-style: italic;
      padding-top: 0;
      padding-bottom: 0;
      font-weight: bold;
      line-height: 2.5rem; }

    .ajd--wrapper {
      @media (min-width: $desktop-min-width) {
        margin-left: 2rem; }

      h3 span {
        background-color: $primary-red;
        color: white; } }


    ul {
      li {
        font-size: 0.9rem;
        list-style-type: disc;
        color: $primary-red;

        span {
          color: $primary-blue; } } } }

  $etapes-descr--wrapper-font-size: 1.1rem;
  #etapes-descr--wrapper {
    .thumb {
      @include wrapper();
      @include barlow-regular();
      $height: 3rem;
      height: $height;
      border-top-right-radius: $height;
      border-bottom-right-radius: $height;
      background-color: white;
      color: $primary-blue;
      line-height: 1.5rem;
      width: 95vw;
      padding-right: 3rem;
      font-size: $etapes-descr--wrapper-font-size;
      display: flex;
      flex-direction: row;
      align-items: center;

      @media (min-width: $desktop-min-width) {
        font-size: 1.25rem;
        transform: translateY(75%);
        height: 2.5rem;
        width: calc(#{$desktop-page-margin-left} + 47rem); }

      @media (min-width: $tablet-min-width) {
        font-size: 1.5rem; }


      strong {
        @include barlow-bold(); } } }


  #index-footer--wrapper {
    display: flex;
    justify-content: center;
    margin: 3rem 0;

    a {

      &:first-child {
        margin-right: 1.5rem;

        @media (min-width: $desktop-min-width) {
          justify-content: flex-end;
          margin-right: 2.5rem;
          padding-right: 2.5rem;
          border-right: 2px solid #bfbfbf; } }

      img {
        max-height: 2rem; } } }

  #contact--wrapper {
    display: none;
    background-color: $primary-grey;
    border-top: 1px solid white;

    .wrapper2 {
      width: 25rem;
      padding: 6rem 0; }

    h3 {
      @include barlow-extra-bold();
      font-size: 2rem;
      margin-bottom: 0;
      margin-top: 0;
      text-align: center; }

    h4 {
      @include barlow-bold();
      font-size: 1rem;
      color: $secondary-grey;
      margin-top: 1rem;
      text-align: center; }

    h5 {
      display: none; }

    form {
      @include flex-column();
      justify-content: flex-start;

      button {
        margin-left: 0.75rem; }

      .section.row {
        margin-bottom: 0;
        padding-bottom: 0;

        & > .col.s12 > .layout-row {
          margin-bottom: 0;

          & > div {
            margin-bottom: 0;

            & > div > div {
              margin-bottom: 0; } } } } }


    input, textarea, button {
      @include barlow-condensed-bold();
      $height: 2rem;
      background-color: $secondary-black;
      border-radius: calc(#{$height});
      border: none;
      color: white;
      height: $height;
      box-sizing: border-box;
      padding-left: 2rem;
      margin: 0;

      &:focus {
        background-color: $primary-black;
        border: 2px solid #8799a3; } }

    textarea {
      min-height: 15rem;
      border-radius: 1.5rem; }

    #id_message_container {
      label {
        align-items: flex-start;
        padding-top: 1rem; } }

    label {
      top: 0;
      padding-left: 2.8rem;
      left: 0;
      right: 0;
      font-size: 0.8rem;
      height: 100%;
      display: flex;
      align-items: center;
      transform: inherit;

      &.active {
        transform: translateY(-20px) scale(0.8)translateX(5%);
        opacity: 0;
        pointer-events: none; } }

    button {
      background-color: $primary-green;
      height: 2.4rem;
      padding: 0; }


    @media (min-width: $desktop-min-width) {
      @include flex-center();
      @include flex-column();
      justify-content: center; } }

  #processes--wrapper {
    @include flex-column();
    @include wrapper();

    background-color: $primary-blue;
    padding-top: 6rem;
    padding-bottom: 10rem;
    padding-right: 2rem;
    justify-content: center;

    @media (min-width: 10rem) {
      padding-bottom: 12rem; }

    @media (min-width: $desktop-min-width) {
      flex-direction: row;
      padding-left: 0;
      padding-right: 0rem; }

    .process--wrapper {
      position: relative;
      $paddingLeft: 4rem;
      padding: 0;
      align-items: flex-start;
      padding-left: $paddingLeft;
      flex-direction: column;

      @media (min-width: $desktop-min-width) {
        flex-direction: column;
        padding-left: 0;
        max-width: 15rem;
        margin: 0 1rem; }

      .title--wrapper {
        display: flex;
        position: relative;
        width: 100%;

        @media (min-width: $desktop-min-width) {
          flex-direction: column; }

        img {
          $width: 3.5rem;
          max-width: $width;
          position: absolute;
          bottom: 0;
          right: calc(100% + 1rem);
          margin: 0;
          width: auto;
          height: auto;

          @media (min-width: $desktop-min-width) {
            position: inherit;
            right: inherit;
            padding-top: 1.5rem; }

          &.mobile {
            @media (min-width: $desktop-min-width) {
              display: none; } }

          &.desktop {
            display: none;

            @media (min-width: $desktop-min-width) {
              display: block; }

            &.num-1 {
              margin-left: 2rem;
              align-self: flex-start;
              box-sizing: content-box; }

            &.num-2 {
              max-width: auto;
              max-height: 4rem;
              object-fit: contain;
              padding: 0;
              align-self: center; }

            &.num-3 {
              margin-right: 4rem;
              box-sizing: initial;
              align-self: flex-end; } } }

        h4 {
          @include faustina-bold-italic();
          font-size: 1.2rem;
          margin-bottom: 0;

          &.translate {
            @media (min-width: $desktop-min-width) {
              line-height: 2.5rem; } } } }

      p {
        @include barlow-regular();
        max-width: 20rem;
        font-size: 0.8rem;
        font-size: $etapes-descr--wrapper-font-size;

        @media (min-width: $desktop-min-width) {
          min-height: 5rem; } }

      .deco {
        max-height: 3rem;
        width: auto;
        height: auto;
        align-self: center;
        margin: 0; } }
    .picto-bus, .picto-auto {
      position: absolute;
      display: none;
      top: 50%;
      transform: translateY(-50%);
      width: auto;

      @media (min-width: $desktop-min-width) {
        display: block; } }

    .picto-bus {
      right: calc(140% + 0rem); }

    .picto-auto {
      left: calc(140% + 0rem); } } }

